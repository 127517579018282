import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PlaylistList from './components/PlaylistList';
import TrackList from './components/TrackList';
import HourButtons from './components/HourButtons';
import TrackChart from './components/TrackChart';
import './App.css'; // Import the CSS file

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_CALLBACK_URL;
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const RESPONSE_TYPE = 'token';
const SCOPES = [
  'playlist-modify-public',
  'playlist-modify-private',
  'user-read-email',
  'user-read-private',
].join('%20'); // Join scopes with URL encoding

function App() {
  const [token, setToken] = useState('');
  const [userData, setUserData] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedHours, setSelectedHours] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [newPlaylistUrl, setNewPlaylistUrl] = useState('');

  useEffect(() => {
    const hash = window.location.hash;
    let token = window.localStorage.getItem("token");
    if (!token && hash) {
      token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1];
      window.location.hash = "";
      window.localStorage.setItem("token", token);
    }
    setToken(token);
    if (token) {
      axios.get('https://api.spotify.com/v1/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        setUserData(response.data);
      }).catch(error => {
        if (error.response.status === 401) {
          handleUnauthorized();
        } else {
          console.error(error);
        }
      });
      axios.get('https://api.spotify.com/v1/me/playlists', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        setPlaylists(response.data.items);
      }).catch(error => {
        if (error.response.status === 401) {
          handleUnauthorized();
        } else {
          console.error(error);
        }
      });
    }
  }, []);

  const handleUnauthorized = () => {
    window.localStorage.removeItem("token");
    window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPES}`;
  };

  const togglePlaylistSelection = (playlistId) => {
    setSelectedPlaylists(prevSelectedPlaylists => {
      if (prevSelectedPlaylists.includes(playlistId)) {
        setTracks(prevTracks => prevTracks.filter(track => track.playlistId !== playlistId));
        return prevSelectedPlaylists.filter(id => id !== playlistId);
      } else {
        fetchTracks(playlistId);
        return [...prevSelectedPlaylists, playlistId];
      }
    });
  };

  const fetchTracks = (playlistId) => {
    axios.get(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      const tracksWithPlaylistId = response.data.items.map(track => ({
        ...track,
        playlistId
      }));
      setTracks(prevTracks => {
        const newTracks = [...prevTracks, ...tracksWithPlaylistId];
        const uniqueTracks = Array.from(new Set(newTracks.map(track => track.track.id)))
          .map(id => newTracks.find(track => track.track.id === id));
        return uniqueTracks;
      });
    }).catch(error => {
      if (error.response.status === 401) {
        handleUnauthorized();
      } else {
        console.error(error);
      }
    });
  };

  const sortTracks = (order) => {
    const sortedTracks = [...tracks].sort((a, b) => {
      const timeA = new Date(a.added_at).getHours() * 60 + new Date(a.added_at).getMinutes();
      const timeB = new Date(b.added_at).getHours() * 60 + new Date(b.added_at).getMinutes();
      return order === 'asc' ? timeA - timeB : timeB - timeA;
    });
    setTracks(sortedTracks);
    setSortOrder(order);
  };

  const filterTracksByHours = (tracks, hours) => {
    return tracks.filter(track => {
      const trackHour = new Date(track.added_at).getHours();
      return hours.includes(trackHour);
    });
  };
  
  const toggleHourSelection = (hour) => {
    setSelectedHours(prevSelectedHours => {
       if (prevSelectedHours.includes(hour)) {
        return prevSelectedHours.filter(h => h !== hour);
      } else {
        return [...prevSelectedHours, hour];
      }
    });
  };

  const selectAllPlaylists = () => {
    setSelectedPlaylists(playlists.map(playlist => playlist.id));
    playlists.forEach(playlist => fetchTracks(playlist.id));
  };

  const selectNonePlaylists = () => {
    setSelectedPlaylists([]);
    setTracks([]);
  };

  const selectAllHours = () => {
    setSelectedHours(Array.from({ length: 24 }, (_, hour) => hour));
  };

  const selectNoneHours = () => {
    setSelectedHours([]);
  };

  const saveButtonRef = React.useRef(null);

  const saveFilteredPlaylist = () => {
    const filteredTracks = filterTracksByHours(tracks, selectedHours);
    const trackUris = filteredTracks.map(track => track.track.uri);
    const defaultName = `${selectedHours.join(', ')} tracks`;
    const name = playlistName || defaultName;

    // Create a new playlist
    axios.post(`https://api.spotify.com/v1/users/${userData.id}/playlists`, {
      name: name,
      description: 'A playlist created by Spotify O\'Clock',
      public: false
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      const playlistId = response.data.id;
      const playlistUrl = response.data.external_urls.spotify;

      // Add tracks to the new playlist
      axios.post(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
        uris: trackUris
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then(() => {
        const saveButton = saveButtonRef.current;
        saveButton.textContent = 'Saved!';
        saveButton.style.backgroundColor = 'green';
        setNewPlaylistUrl(playlistUrl);
        setTimeout(() => {
          saveButton.textContent = 'Save Filtered Playlist';
          saveButton.style.backgroundColor = '';
        }, 2000);
      }).catch(error => {
        console.error('Error adding tracks to playlist:', error);
      });
    }).catch(error => {
      console.error('Error creating playlist:', error);
    });
  };

  const logout = () => {
    setToken('');
    window.localStorage.removeItem("token");
  };

  // Calculate track counts by hour
  const trackCountsByHour = Array.from({ length: 24 }, (_, hour) => {
    return tracks.filter(track => new Date(track.added_at).getHours() === hour).length;
  });

  // Filter tracks by selected hours
  const filteredTracks = filterTracksByHours(tracks, selectedHours);

  // Prepare data for TrackChart
  const data = {
    labels: Array.from({ length: 24 }, (_, hour) => `${hour}:00`),
    datasets: [
      {
        label: 'Track Counts',
        data: trackCountsByHour,
        backgroundColor: Array.from({ length: 24 }, (_, hour) =>
          selectedHours.includes(hour) ? 'rgba(29, 185, 84, 1)' : 'rgba(29, 185, 84, 0.2)'
        ), // Change color based on selected hours
        borderColor: 'rgba(29, 185, 84, 1)', /* Spotify green */
        borderWidth: 1,
      },
    ],
  };

  const handleBarClick = (hour) => {
    toggleHourSelection(hour);
  };

  return (
    <div className="App">
      <h1>Spotify O'Clock</h1>
      <p className="placeholder-text">Browse your spotify playlists, sorted by the hour of the day.  Easily find those 2AM tunes 🕑🔊</p>
      {!token ? (
        <div>
          <a className="login-link" href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPES}`}>
            Login to Spotify
          </a>
          <p className="placeholder-text">Log in to Spotify to continue, this app will request permission to read your profile data and playlists, you can optionally save new playlists.</p>
        </div>
      ) : (
        <div>
          <button className="logout-button" onClick={logout}>Logout</button>
          <div className="welcome-message">
            <h3>Welcome, {userData?.display_name}</h3>
          </div>
          <div className="sidebar">
            {selectedPlaylists.length === 0 && (
              <p className="placeholder-text">Start by selecting some playlists</p>
            )}
            {selectedPlaylists.length > 0 && (
              <p className="placeholder-text">Scroll down to see the hourly distribution</p>
            )}
            <div className="scroll-box">
              <PlaylistList
                playlists={playlists}
                selectedPlaylists={selectedPlaylists}
                togglePlaylistSelection={togglePlaylistSelection}
              />
            </div>
            <button onClick={selectAllPlaylists}>Select All Playlists</button>
            <button onClick={selectNonePlaylists}>Select None Playlists</button>
          </div>
          <div className="content">
            {selectedPlaylists.length > 0 && (
              <div>
                <h2>Tracks</h2>
                <TrackChart data={data} onBarClick={handleBarClick} />
                {selectedHours.length === 0 && (
                  <p className="placeholder-text">Select some hours to see the tracks you added at that time</p>
                )}
                <HourButtons
                  trackCountsByHour={trackCountsByHour}
                  selectedHours={selectedHours}
                  toggleHourSelection={toggleHourSelection}
                />

                <button onClick={selectAllHours}>Select All Hours</button>
                <button onClick={selectNoneHours}>Select None Hours</button>
                <div className="scroll-box">
                  <TrackList filteredTracks={filteredTracks} playlists={playlists} />
                </div>
                <button onClick={() => sortTracks('asc')}>Sort by Hour Ascending</button>
                <button onClick={() => sortTracks('desc')}>Sort by Hour Descending</button>
                <input
                  type="text"
                  placeholder="Enter playlist name"
                  value={playlistName}
                  onChange={(e) => setPlaylistName(e.target.value)}
                />
                <button ref={saveButtonRef} onClick={() => saveFilteredPlaylist()}>Save Filtered Playlist</button>
                {newPlaylistUrl && (
                  <p className="placeholder-text">
                    Your new playlist has been created! You can view it <a href={newPlaylistUrl} target="_blank" rel="noopener noreferrer" className="track-link">here</a>.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <footer className="footer">
        <p>Created by <a href="https://github.com/jezuk" target="_blank" rel="noopener noreferrer">jezwold</a></p>
      </footer>
    </div>
  );
}

export default App;