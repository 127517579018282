import React from 'react';

const TrackList = ({ filteredTracks, playlists }) => {
  return (
    <ul>
      {filteredTracks.map(track => (
        <li key={track.track.id}>
          <a href={track.track.external_urls.spotify} target="_blank" rel="noopener noreferrer" className="track-link">
            {track.track.name} by {track.track.artists.map(artist => artist.name).join(', ')}
          </a> - Added on {new Date(track.added_at).toLocaleString()} - From playlist {playlists.find(playlist => playlist.id === track.playlistId)?.name}
        </li>
      ))}
    </ul>
  );
};

export default TrackList;