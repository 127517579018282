import React from 'react';

const HourButtons = ({ trackCountsByHour, selectedHours, toggleHourSelection }) => {
  return (
    <div>
      {trackCountsByHour.map((count, hour) => (
        <button
          key={hour}
          onClick={() => toggleHourSelection(hour)}
          className={`hour-button ${selectedHours.includes(hour) ? 'selected' : ''} ${count === 0 ? 'disabled' : ''}`}
          disabled={count === 0}
        >
          {hour}:00 ({count})
        </button>
      ))}
    </div>
  );
};

export default HourButtons;