import React from 'react';

const PlaylistList = ({ playlists, selectedPlaylists, togglePlaylistSelection }) => {
  return (
    <div>
      <h2>Your Playlists</h2>
      <ul>
        {playlists.map(playlist => (
          <li
            key={playlist.id}
            onClick={() => togglePlaylistSelection(playlist.id)}
            className={`playlist-item ${selectedPlaylists.includes(playlist.id) ? 'selected' : ''}`}
          >
            {playlist.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlaylistList;