import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';

// Register the "category" and "linear" scales, and the "bar" element
Chart.register(CategoryScale, LinearScale, BarElement);

const TrackChart = ({ data, onBarClick }) => {
  const options = {
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        onBarClick(elementIndex);
      }
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h2>Track Counts by Hour</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default TrackChart;